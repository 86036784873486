import axios from 'axios';
import { h } from 'preact';
import { useTranslation } from 'preact-i18next';
import { useState, useEffect } from 'preact/hooks';

const Header = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const { i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [flagCode, setFlagCode] = useState('US');

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    localStorage.setItem('language', language);
    setMenuOpen(false);
    i18n.changeLanguage(language);
  };

  const flagMap = {
    de: 'DE',
    en: 'US',
    fr: 'FR',
    it: 'IT',
    pt: 'PT',
    br: 'BR',
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    axios.post('api/visit', {
      fbc: fbclid,
    });
    localStorage.setItem('fbclid', fbclid);
  }, []);

  useEffect(() => {
    const savedLanguage =
      localStorage.getItem('language') ||
      (navigator && navigator.language.replace('pt-', ''));
    setSelectedLanguage(savedLanguage.toLowerCase() || 'en');
    i18n.changeLanguage(savedLanguage.toLowerCase() || 'en');
  }, []);

  useEffect(() => {
    setFlagCode(
      flagMap[selectedLanguage.toLowerCase()] ||
        (navigator && navigator.language.replace('pt-', '').toUpperCase()) ||
        'US',
    );
  }, [selectedLanguage]);

  return (
    <div
      id="header"
      className="relative px-4 md:px-0 bg-zinc-950 shadow-md py-2"
    >
      <header className="flex h-14 max-w-7xl mx-auto items-center flex-row justify-between">
        <a href="/">
          <div className="flex gap-2 items-start justify-start w-full">
            <div className="flex items-center gap-2">
              <img src="/favicon.ico" alt="logo" className="w-6 h-6" />
              <span className="text-xl font-bold text-pink-500">
                Ritmo do Amor
              </span>
            </div>
          </div>
        </a>
        <div className="relative">
          <button
            className="bg-white border border-gray-300 rounded-md p-2 flex items-center"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <img
              alt={selectedLanguage}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${flagCode}.svg`}
              className="w-6 h-4"
            />
            <span className="ml-2 capitalize">{selectedLanguage}</span>
          </button>
          {menuOpen && (
            <div className="absolute bg-white border border-gray-300 rounded-md mt-2 w-40 right-0 z-20">
              {Object.entries(flagMap).map(([lang, flag]) => (
                <div
                  key={lang}
                  className="p-2 flex items-center cursor-pointer hover:bg-gray-100"
                  onClick={() => handleLanguageChange(lang)}
                >
                  <img
                    alt={lang}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${flag}.svg`}
                    className="w-6 h-4"
                  />
                  <span className="ml-2">
                    {lang.charAt(0).toUpperCase() + lang.slice(1)}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
