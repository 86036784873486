import { h } from 'preact';
import { Route, Router } from 'preact-router';
import { I18nextProvider } from 'preact-i18next';
import i18n from '../i18n';

import Header from './header';
import Footer from './footer';
import Home from '../routes/home';
import PrivacyPolicy from '../routes/privacy-policy/index';
import TermsOfUse from '../routes/terms-of-use/index';
import PaymentCancelled from '../routes/cancel';
import PaymentSuccess from '../routes/payment-success/index';

const App = () => (
  <I18nextProvider i18n={i18n}>
    <div id="app" className="min-h-screen flex flex-col">
      <Header />
      <Router>
        <Route path="/" component={Home} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/payment-success" component={PaymentSuccess} />
        <Route path="/cancel" component={PaymentCancelled} />
      </Router>
      <Footer />
    </div>
  </I18nextProvider>
);

export default App;
