import { h } from 'preact';
import { useTranslation } from 'preact-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gradient-to-r bg-zinc-950 text-white py-4">
      <div className="max-w-screen-xl mx-auto flex flex-col items-center gap-2">
        <div className="text-xs font-light">
          <span>
            © {new Date().getFullYear()} Ritmo do Amor.{' '}
            {t('todos_os_direitos_reservados')}
          </span>
        </div>
        <div className="flex gap-6 text-sm">
          <a href="/privacy-policy" className="hover:underline">
            {t('política_de_privacidade')}
          </a>
          <a href="/terms-of-use" className="hover:underline">
            {t('termos_de_uso')}
          </a>
        </div>
        <div className="mt-2 bg-white p-1 rounded-full">
          <a href="/" className="bg-white">
            <img src="/favicon.ico" alt="logo" className="w-6 h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
